<template>
  <div style="text-align: left">
    <article>
      <section>
        <div class="container mt-5">
          <div class="mb-3">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/smartlodge/admin/' }">Home</el-breadcrumb-item>
              <el-breadcrumb-item :to="{ path: '/smartlodge/admin/categories' }">Categories</el-breadcrumb-item>
              <el-breadcrumb-item>Add Product</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <h1
            class="mb-4"
            style="font-size: 1.1em; font-weight: 600;"
          >Add Room Category</h1>
          <div v-loading="isLoading">
            <el-form
              :model="roomCategoryForm"
              :rules="categoryRules"
              ref="roomCategoryForm"
              class="demo-roomCategoryForm2"
            >
              <div class="row">
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Category Name</label>
                  <el-form-item prop="categoryName">
                    <el-input
                      v-model="roomCategoryForm.categoryName"
                      placeholder="Category Name"
                      :disabled="isUploadingFile"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Price Per Night</label>
                  <el-form-item prop="pricePerNight">
                    <el-input
                      v-model="roomCategoryForm.pricePerNight"
                      placeholder="UGX 20000"
                      :disabled="isUploadingFile"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Max Occupancy</label>
                  <el-form-item prop="maxOccupancy">
                    <el-input
                      v-model="roomCategoryForm.maxOccupancy"
                      placeholder="100"
                      :disabled="isUploadingFile"
                    ></el-input>
                  </el-form-item>
                </div>
                <div class="col-md-6">
                  <label class="mt-3 mb-2">Brief Description</label>
                  <el-form-item prop="description">
                    <el-input
                      v-model="roomCategoryForm.description"
                      placeholder="Product Brief Description"
                      :disabled="isUploadingFile"
                    ></el-input>
                  </el-form-item>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <label class="mt-3 mb-2">Category Main Image</label><br />
                  <el-form-item>
                    <el-upload
                      class="upload-demo"
                      drag
                      action="''"
                      :on-change="handlePreview"
                      :on-remove="handleRemove"
                      :file-list="fileLists"
                      :auto-upload="false"
                      multiple
                    >
                      <div v-if="!isUploadingFile">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">
                          Drop file here or <em>click to upload</em>
                        </div>
                      </div>
                      <div v-else>
                        <el-progress
                          v-if="imageUploadingProgress != 100"
                          type="circle"
                          :color="progressColors"
                          :percentage="imageUploadingProgress"
                          :width="80"
                          :stroke-width="4"
                        ></el-progress>
                        <el-progress
                          v-else
                          type="circle"
                          :percentage="100"
                          status="success"
                          :width="80"
                          :stroke-width="4"
                        ></el-progress>
                        <div style="font-size: 0.8em; color: rgba(0, 0, 0, 0.8)">
                          {{
                          imageUploadingProgress == 100
                            ? "Uploaded"
                            : "Uploading..."
                        }}
                        </div>
                      </div>
                      <!-- <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div> -->
                    </el-upload>
                  </el-form-item>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <label class="mt-3 mb-2">Category Slide Images <strong>(Strictly 4 images)</strong></label><br />
                  <!-- <el-form-item>
                    <el-upload
                      action="''"
                      :on-change="handleChange"
                      :on-remove="handleRemoveTwo"
                      list-type="picture-card"
                      :auto-upload="false"
                      :file-list="fileLists_many"
                      multiple
                      :show-file-list="true"
                    >
                      <i
                        slot="default"
                        class="el-icon-plus"
                      ></i>
                      <div
                        slot="file"
                        slot-scope="{file}"
                      >
                        <img
                          class="el-upload-list__item-thumbnail"
                          :src="file.url"
                          alt=""
                        >
                        <span class="el-upload-list__item-actions">
                          <span
                            class="el-upload-list__item-preview"
                            @click="handlePictureCardPreview(file)"
                          >
                            <i class="el-icon-zoom-in"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleDownload(file)"
                          >
                            <i class="el-icon-download"></i>
                          </span>
                          <span
                            v-if="!disabled"
                            class="el-upload-list__item-delete"
                            @click="handleRemoveAll(file)"
                          >
                            <i class="el-icon-delete"></i>
                          </span>
                        </span>
                      </div>
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible">
                      <img
                        width="100%"
                        :src="dialogImageUrl"
                        alt=""
                      >
                    </el-dialog>
                  </el-form-item> -->
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <el-upload
                    action="''"
                    :on-change="handleChange"
                    :on-remove="handleRemoveTwo"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :auto-upload="false"
                    :file-list="fileLists_many"
                    multiple
                    :show-file-list="true"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisibleTwo">
                    <img
                      width="100%"
                      :src="dialogImageUrlTwo"
                      alt=""
                    >
                  </el-dialog>
                </div>
              </div>

              <hr />
              <el-form-item class="text-center">
                <div
                  v-if="isUploadingFile"
                  class="d-flex justify-content-center"
                >
                  <ScaleOut />
                </div>
                <el-button
                  type="primary"
                  v-else
                  @click="addRoomCategory('roomCategoryForm')"
                >Add Room Category</el-button>
              </el-form-item>
            </el-form>

            <div class="text-center">
              <div class="text-center">
                <small>© 2024, All rights reserved.</small>
              </div>
              <div class="text-center">
                <!--<small>Design by <a href="https://ovalspace.co" style="color: black;"><strong>Ovalspace</strong></a></small> -->
              </div>
            </div>
          </div>
        </div>
      </section>
    </article>
  </div>
</template>


<script>
import ScaleOut from "@/components/backoffice/scale-out-component.vue";
// import Editor from "@tinymce/tinymce-vue";
export default {
  components: {
    ScaleOut,
    // editor: Editor,
  },
  data() {
    return {
      addPackageModal: false,
      selectedFile: null,
      selectedSlideFile: null,
      selectedSlideFiles: [],
      isUploadingFile: false,
      isLoading: false,
      value: "",
      content: "",
      loading: false,
      loadingError: false,
      imageUploadingProgress: 0,
      progressColors: [
        { color: "#f56c6c", percentage: 25 },
        { color: "#e6a23c", percentage: 50 },
        { color: "#1989fa", percentage: 75 },
        { color: "#6f7ad3", percentage: 100 },
      ],
      roomCategoryForm: {
        categoryName: "",
        pricePerNight: "",
        maxOccupancy: "",
        description: "",
      },
      fileLists: [],
      fileLists_many: [],

      categoryRules: {
        categoryName: [
          {
            required: true,
            message: "Category Name is required",
            trigger: "blur",
          },
        ],
        pricePerNight: [
          {
            required: true,
            message: "Price Per Night is required",
            trigger: "blur",
          },
        ],
        maxOccupancy: [
          {
            required: true,
            message: "Max Occupancy is required",
            trigger: "blur",
          },
        ],
        description: [
          {
            required: true,
            message: "Product Brief Description is required",
            trigger: "blur",
          },
        ],
      },

      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,

      dialogImageUrlTwo: "",
      dialogVisibleTwo: false,
    };
  },

  methods: {
    handleClose(done) {
      done();
    },
    handlePreview(file) {
      this.fileLists = [file];
      this.selectedFile = file.raw;
      console.log(this.selectedFile);
    },

    handleChange(file, fileList) {
      this.fileLists_many = fileList;
      console.log(file.raw);
      console.log(this.fileLists_many);
      this.selectedSlideFile = file.raw;
      // this.selectedSlideFiles.push(this.selectedSlideFile);
      this.selectedSlideFiles = this.fileLists_many.map((item) => {
        return item.raw;
      });
      console.log(this.selectedSlideFiles);
    },
    handleRemove() {
      this.selectedFile = null;
    },

    handleRemoveAll(file) {
      console.log(file);
    },

    handleDownload(file) {
      console.log(file);
    },

    async addRoomCategory(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isUploadingFile = true;
          if (this.selectedFile == null) {
            this.$notify({
              title: "Empty",
              message: "Profile picture is empty",
              type: "warning",
            });
          }

          try {
            this.isUploadingFile = true;
            const formData = new FormData();
            formData.append("categoryName", this.roomCategoryForm.categoryName);
            formData.append("pricePerNight", this.roomCategoryForm.pricePerNight);
            formData.append(
              "maxOccupancy",
              this.roomCategoryForm.maxOccupancy
            );
            formData.append(
              "description",
              this.roomCategoryForm.description
            );
            formData.append("createdBy", this.$store.state.userId);
            formData.append("categoryImage", this.selectedFile);
            for (let i = 0; i < this.selectedSlideFiles.length; i++) {
              formData.append(
                "slideRoomImages" + i,
                this.selectedSlideFiles[i]
              );
            }
            let request = await this.$http.post(`categories`, formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
              onUploadProgress: (progressEvent) => {
                this.imageUploadingProgress = +(
                  (progressEvent.loaded / progressEvent.total) *
                  100
                ).toFixed(0);
              },
            });
            if (
              request.data.success &&
              request.data.message == "ROOM_CREATED_SUCCESSFULLY"
            ) {
              this.$router.push({
                path: `/smartlodge/admin/categories`,
              });
              return this.$notify({
                title: "Success",
                message: "Room Category Added Successfully",
                type: "success",
              });
            } else if (request.data.message == "safari_tour  exists") {
              return this.$notify({
                title: "Duplicate",
                message: "Package Exists",
                type: "warning",
              });
            } else throw "UNEXPECTED_RESPONSE";
          } catch (error) {
            if (error.message == "NetworkError") {
              this.isUploadingFile = false;
              return this.$notify({
                title: "Connection failed",
                message: "A network error occurred please try again",
                type: "warning",
              });
            }
            this.isUploadingFile = false;
            return this.$notify({
              title: "Upload Failed",
              message: "Unable to Product, Please try again",
              type: "error",
            });
          } finally {
            this.isUploadingFile = false;
          }
        } else {
          return false;
        }
      });
    },

    handleRemoveTwo(file, fileLists) {
      console.log(file, fileLists);
    },

    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped>
.addBtn {
  background-color: green;
  color: white;
}
input[type="text"],
select,
textarea {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  background-color: #ffffff;
  font-size: 0.9em;
}

.search_add_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.search_by_input {
  width: 400px !important;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .search_add_section {
    display: block;
  }
  .addbtn {
    width: 100%;
    margin-top: 10px;
  }
  .search_by_input {
    width: 100% !important;
  }
}
</style>

<style>
.safari_dialogs .el-dialog {
  width: 60%;
}

/* ##Device = Tablets, Ipads (portrait) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) {
  .safari_dialogs .el-dialog {
    width: 60%;
  }
}

/* ##Device = Tablets, Ipads (landscape) ##Screen = B/w 768px to 1024px */
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .safari_dialogs .el-dialog {
    width: 70%;
  }
}

/* ##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px */
@media (min-width: 481px) and (max-width: 767px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}

/* ##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px */
@media (min-width: 320px) and (max-width: 480px) {
  .safari_dialogs .el-dialog {
    width: 90%;
  }
}
</style>
